<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10706')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-close">{{t('10030')}}</i>
			</a>
		</div>
	</nav>
	<div class="sb_container">
		<img src="https://admin.hellostay.co.kr/static/Data/EData/Promo/sb_img2.png" alt="H_SALAD">
		<img src="@/assets/images/Sbdeajeon_main2.png" alt="H_SALAD">
		<p>{{t('10713')}}</p>
		<span>{{t('10714')}}</span>
		<ul class="loc_select">
			<li>
				<span>{{t('10715')}}</span>
				<select class="sb_select" v-model="locaition1"  @change="handleMenu(locaition1)">
					<!-- [D] 활성화된 지역에 active 클래스 추가 -->
					<!-- [REST] 지역 분류 관리자 페이지에서 설정 -->
						<option value="">{{t('10716')}}</option>
						<option 
							v-for="menu in menus" 
							:key="menu.id" 
							:class="{ active: selectedMenuId === menu.id}" 
							 :value="menu.id"
						>
							<a>
								{{menu.name}}
							</a>
					</option>
				</select>
			</li>
			<li>
				<span>{{t('10717')}}</span>
				<select class="sb_select" v-model="locaition2">
					<option  value="" selected>{{t('10716')}}</option>
					<option v-for="location in locations" :value="location.id" :key="location.id" >
						<a v-on:click="handleSelectLocation(location.id)">
							{{location.name}} 
							<!-- <span class="layer__badge">{{location.count}}</span> -->
						</a>
					</option>
				</select>
			</li>
		</ul>
			<p>{{t('10718')}}</p>
				<span>숙박대전 쿠폰 발급을 위해서 개인정보제공에 관한<br>약관 확인 및 동의가 필수입니다.</span><!-- 재번역체크 -->
							<ul class="agrees" >
								<li>
									<div class="checkbox">
										<label>
											<input type="checkbox" class="chk blind" :checked="allChecked" @change="allCheck($event)">
											<span class="checkbox__bg"></span>
											<span class="agree__title">{{t('10720')}}</span>
										</label>
									</div>
								</li>
								<li>
									<div class="checkbox">
										<label>
											<input type="checkbox" class="chk blind" value="privacy" v-model="checkedList">
											<span class="checkbox__bg"></span>
											<span class="agree__title">{{t('10116')}}</span>
										</label>
									</div>
									<button type="button">
										<i class="icon icon-right--light" @click="handleOpenTerm('agree')">{{t('10115')}}</i>
									</button>
								</li>
								<li>
									<div class="checkbox">
										<label>
											<input type="checkbox" class="chk blind" value="privacy3" v-model="checkedList">
											<span class="checkbox__bg"></span>
											<span class="agree__title">{{t('10488')}}</span>
										</label>
									</div>
									<button type="button">
										<i class="icon icon-right--light" @click="handleOpenTerm('third')">{{t('10115')}}</i>
									</button>
								</li>
							</ul>
		
		
		<a class="SB_subbit" @click="handleClickSb">{{t('10721')}}
                <i style="margin-top:13px;" class="icon icon-right--white"></i></a>
				
		<img src="@/assets/images/Sbdeajeon_main3.png" alt="H_SALAD">
	</div>
	<!-- // nav -->
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<TermsAgree v-if="openTerm === 'agree'" :handleClose="handleCloseTerm" />
	<TermsThird v-if="openTerm === 'third'" :handleClose="handleCloseTerm" />
</template>
<style>
	.wrap{padding-bottom:0px;}
</style>
<script>
import { useStore } from "vuex"
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from 'vue-router';
import dayjs from 'dayjs'
import "dayjs/locale/ko";
import mpj from "@/urls/mpj01";
import ErrorAlert from "@/components/layers/Message_alert";
import TermsThird from "@/components/layers/Terms_third";
import TermsAgree from "@/components/layers/Terms_agree";
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 

dayjs.locale("ko");

export default {
	components: {
		ErrorAlert,
		TermsAgree,
		TermsThird
	},
	setup: function() {
		const store = useStore();
		const selectedMenuId = ref("1");
		const router = useRouter();
		const route = useRoute();
		const { t }= useI18n() 
		const loc_cd1 = ref([{
			name: "서울",
			type: "1",
		},{
			name: "인천",
			type: "2",
		},{
			name: "대전",
			type: "3",
		},{
			name: "대구",
			type: "4",
		},{
			name: "광주",
			type: "5",
		},{
			name: "부산",
			type: "6",
		},{
			name: "울산",
			type: "7",
		},{
			name: "세종시",
			type: "8",
		},{
			name: "경기도",
			type: "31",
		},{
			name: "강원도",
			type: "32",
		},{
			name: "충청북도",
			type: "33",
		},{
			name: "충청남도",
			type: "34",
		},{
			name: "강상북도",
			type: "35",
		},{
			name: "경상남도",
			type: "36",
		},{
			name: "전라북도",
			type: "37",
		},{
			name: "전라남도",
			type: "38",
		},{
			name: "제주도",
			type: "39",
		}
		])
		const loc_cd2 = ref([{name:"강남구",type:"1",type2:"1"},
		{name:"강동구",type:"1",type2:"2"},
		{name:"강북구",type:"1",type2:"3"},
		{name:"강서구",type:"1",type2:"4"},
		{name:"관악구",type:"1",type2:"5"},
		{name:"광진구",type:"1",type2:"6"},
		{name:"구로구",type:"1",type2:"7"},
		{name:"금천구",type:"1",type2:"8"},
		{name:"노원구",type:"1",type2:"9"},
		{name:"도봉구",type:"1",type2:"10"},
		{name:"동대문구",type:"1",type2:"11"},
		{name:"동작구",type:"1",type2:"12"},
		{name:"마포구",type:"1",type2:"13"},
		{name:"서대문구",type:"1",type2:"14"},
		{name:"서초구",type:"1",type2:"15"},
		{name:"성동구",type:"1",type2:"16"},
		{name:"성북구",type:"1",type2:"17"},
		{name:"송파구",type:"1",type2:"18"},
		{name:"양천구",type:"1",type2:"19"},
		{name:"영등포구",type:"1",type2:"20"},
		{name:"용산구",type:"1",type2:"21"},
		{name:"은평구",type:"1",type2:"22"},
		{name:"종로구",type:"1",type2:"23"},
		{name:"중구",type:"1",type2:"24"},
		{name:"중랑구",type:"1",type2:"25"},
		{name:"강화군",type:"2",type2:"1"},
		{name:"계양구",type:"2",type2:"2"},
		{name:"미추홀구",type:"2",type2:"3"},
		{name:"남동구",type:"2",type2:"4"},
		{name:"동구",type:"2",type2:"5"},
		{name:"부평구",type:"2",type2:"6"},
		{name:"서구",type:"2",type2:"7"},
		{name:"연수구",type:"2",type2:"8"},
		{name:"옹진군",type:"2",type2:"9"},
		{name:"중구",type:"2",type2:"10"},
		{name:"대덕구",type:"3",type2:"1"},
		{name:"동구",type:"3",type2:"2"},
		{name:"서구",type:"3",type2:"3"},
		{name:"유성구",type:"3",type2:"4"},
		{name:"중구",type:"3",type2:"5"},
		{name:"남구",type:"4",type2:"1"},
		{name:"달서구",type:"4",type2:"2"},
		{name:"달성군",type:"4",type2:"3"},
		{name:"동구",type:"4",type2:"4"},
		{name:"북구",type:"4",type2:"5"},
		{name:"서구",type:"4",type2:"6"},
		{name:"수성구",type:"4",type2:"7"},
		{name:"중구",type:"4",type2:"8"},
		{name:"광산구",type:"5",type2:"1"},
		{name:"남구",type:"5",type2:"2"},
		{name:"동구",type:"5",type2:"3"},
		{name:"북구",type:"5",type2:"4"},
		{name:"서구",type:"5",type2:"5"},
		{name:"강서구",type:"6",type2:"1"},
		{name:"금정구",type:"6",type2:"2"},
		{name:"기장군",type:"6",type2:"3"},
		{name:"남구",type:"6",type2:"4"},
		{name:"동구",type:"6",type2:"5"},
		{name:"동래구",type:"6",type2:"6"},
		{name:"부산진구",type:"6",type2:"7"},
		{name:"북구",type:"6",type2:"8"},
		{name:"사상구",type:"6",type2:"9"},
		{name:"사하구",type:"6",type2:"10"},
		{name:"서구",type:"6",type2:"11"},
		{name:"수영구",type:"6",type2:"12"},
		{name:"연제구",type:"6",type2:"13"},
		{name:"영도구",type:"6",type2:"14"},
		{name:"중구",type:"6",type2:"15"},
		{name:"해운대구",type:"6",type2:"16"},
		{name:"중구",type:"7",type2:"1"},
		{name:"남구",type:"7",type2:"2"},
		{name:"동구",type:"7",type2:"3"},
		{name:"북구",type:"7",type2:"4"},
		{name:"울주군",type:"7",type2:"5"},
		{name:"세종시",type:"8",type2:"1"},
		{name:"가평군",type:"31",type2:"1"},
		{name:"고양시",type:"31",type2:"2"},
		{name:"과천시",type:"31",type2:"3"},
		{name:"광명시",type:"31",type2:"4"},
		{name:"광주시",type:"31",type2:"5"},
		{name:"구리시",type:"31",type2:"6"},
		{name:"군포시",type:"31",type2:"7"},
		{name:"김포시",type:"31",type2:"8"},
		{name:"남양주시",type:"31",type2:"9"},
		{name:"동두천시",type:"31",type2:"10"},
		{name:"부천시",type:"31",type2:"11"},
		{name:"성남시",type:"31",type2:"12"},
		{name:"수원시",type:"31",type2:"13"},
		{name:"시흥시",type:"31",type2:"14"},
		{name:"안산시",type:"31",type2:"15"},
		{name:"안성시",type:"31",type2:"16"},
		{name:"안양시",type:"31",type2:"17"},
		{name:"양주시",type:"31",type2:"18"},
		{name:"양평군",type:"31",type2:"19"},
		{name:"여주시",type:"31",type2:"20"},
		{name:"연천군",type:"31",type2:"21"},
		{name:"오산시",type:"31",type2:"22"},
		{name:"용인시",type:"31",type2:"23"},
		{name:"의왕시",type:"31",type2:"24"},
		{name:"의정부시",type:"31",type2:"25"},
		{name:"이천시",type:"31",type2:"26"},
		{name:"파주시",type:"31",type2:"27"},
		{name:"평택시",type:"31",type2:"28"},
		{name:"포천시",type:"31",type2:"29"},
		{name:"하남시",type:"31",type2:"30"},
		{name:"화성시",type:"31",type2:"31"},
		{name:"강릉시",type:"32",type2:"1"},
		{name:"고성군",type:"32",type2:"2"},
		{name:"동해시",type:"32",type2:"3"},
		{name:"삼척시",type:"32",type2:"4"},
		{name:"속초시",type:"32",type2:"5"},
		{name:"양구군",type:"32",type2:"6"},
		{name:"양양군",type:"32",type2:"7"},
		{name:"영월군",type:"32",type2:"8"},
		{name:"원주시",type:"32",type2:"9"},
		{name:"인제군",type:"32",type2:"10"},
		{name:"정선군",type:"32",type2:"11"},
		{name:"철원군",type:"32",type2:"12"},
		{name:"춘천시",type:"32",type2:"13"},
		{name:"태백시",type:"32",type2:"14"},
		{name:"평창군",type:"32",type2:"15"},
		{name:"홍천군",type:"32",type2:"16"},
		{name:"화천군",type:"32",type2:"17"},
		{name:"횡성군",type:"32",type2:"18"},
		{name:"괴산군",type:"33",type2:"1"},
		{name:"단양군",type:"33",type2:"2"},
		{name:"보은군",type:"33",type2:"3"},
		{name:"영동군",type:"33",type2:"4"},
		{name:"옥천군",type:"33",type2:"5"},
		{name:"음성군",type:"33",type2:"6"},
		{name:"제천시",type:"33",type2:"7"},
		{name:"진천군",type:"33",type2:"8"},
		{name:"청주시",type:"33",type2:"10"},
		{name:"충주시",type:"33",type2:"11"},
		{name:"증평군",type:"33",type2:"12"},
		{name:"공주시",type:"34",type2:"1"},
		{name:"금산군",type:"34",type2:"2"},
		{name:"논산시",type:"34",type2:"3"},
		{name:"당진시",type:"34",type2:"4"},
		{name:"보령시",type:"34",type2:"5"},
		{name:"부여군",type:"34",type2:"6"},
		{name:"서산시",type:"34",type2:"7"},
		{name:"서천군",type:"34",type2:"8"},
		{name:"아산시",type:"34",type2:"9"},
		{name:"예산군",type:"34",type2:"11"},
		{name:"천안시",type:"34",type2:"12"},
		{name:"청양군",type:"34",type2:"13"},
		{name:"태안군",type:"34",type2:"14"},
		{name:"홍성군",type:"34",type2:"15"},
		{name:"계룡시",type:"34",type2:"16"},
		{name:"경산시",type:"35",type2:"1"},
		{name:"경주시",type:"35",type2:"2"},
		{name:"고령군",type:"35",type2:"3"},
		{name:"구미시",type:"35",type2:"4"},
		{name:"군위군",type:"35",type2:"5"},
		{name:"김천시",type:"35",type2:"6"},
		{name:"문경시",type:"35",type2:"7"},
		{name:"봉화군",type:"35",type2:"8"},
		{name:"상주시",type:"35",type2:"9"},
		{name:"성주군",type:"35",type2:"10"},
		{name:"안동시",type:"35",type2:"11"},
		{name:"영덕군",type:"35",type2:"12"},
		{name:"영양군",type:"35",type2:"13"},
		{name:"영주시",type:"35",type2:"14"},
		{name:"영천시",type:"35",type2:"15"},
		{name:"예천군",type:"35",type2:"16"},
		{name:"울릉군",type:"35",type2:"17"},
		{name:"울진군",type:"35",type2:"18"},
		{name:"의성군",type:"35",type2:"19"},
		{name:"청도군",type:"35",type2:"20"},
		{name:"청송군",type:"35",type2:"21"},
		{name:"칠곡군",type:"35",type2:"22"},
		{name:"포항시",type:"35",type2:"23"},
		{name:"거제시",type:"36",type2:"1"},
		{name:"거창군",type:"36",type2:"2"},
		{name:"고성군",type:"36",type2:"3"},
		{name:"김해시",type:"36",type2:"4"},
		{name:"남해군",type:"36",type2:"5"},
		{name:"밀양시",type:"36",type2:"7"},
		{name:"사천시",type:"36",type2:"8"},
		{name:"산청군",type:"36",type2:"9"},
		{name:"양산시",type:"36",type2:"10"},
		{name:"의령군",type:"36",type2:"12"},
		{name:"진주시",type:"36",type2:"13"},
		{name:"창녕군",type:"36",type2:"15"},
		{name:"창원시",type:"36",type2:"16"},
		{name:"통영시",type:"36",type2:"17"},
		{name:"하동군",type:"36",type2:"18"},
		{name:"함안군",type:"36",type2:"19"},
		{name:"함양군",type:"36",type2:"20"},
		{name:"합천군",type:"36",type2:"21"},
		{name:"고창군",type:"37",type2:"1"},
		{name:"군산시",type:"37",type2:"2"},
		{name:"김제시",type:"37",type2:"3"},
		{name:"남원시",type:"37",type2:"4"},
		{name:"무주군",type:"37",type2:"5"},
		{name:"부안군",type:"37",type2:"6"},
		{name:"순창군",type:"37",type2:"7"},
		{name:"완주군",type:"37",type2:"8"},
		{name:"익산시",type:"37",type2:"9"},
		{name:"임실군",type:"37",type2:"10"},
		{name:"장수군",type:"37",type2:"11"},
		{name:"전주시",type:"37",type2:"12"},
		{name:"정읍시",type:"37",type2:"13"},
		{name:"진안군",type:"37",type2:"14"},
		{name:"강진군",type:"38",type2:"1"},
		{name:"고흥군",type:"38",type2:"2"},
		{name:"곡성군",type:"38",type2:"3"},
		{name:"광양시",type:"38",type2:"4"},
		{name:"구례군",type:"38",type2:"5"},
		{name:"나주시",type:"38",type2:"6"},
		{name:"담양군",type:"38",type2:"7"},
		{name:"목포시",type:"38",type2:"8"},
		{name:"무안군",type:"38",type2:"9"},
		{name:"보성군",type:"38",type2:"10"},
		{name:"순천시",type:"38",type2:"11"},
		{name:"신안군",type:"38",type2:"12"},
		{name:"여수시",type:"38",type2:"13"},
		{name:"영광군",type:"38",type2:"16"},
		{name:"영암군",type:"38",type2:"17"},
		{name:"완도군",type:"38",type2:"18"},
		{name:"장성군",type:"38",type2:"19"},
		{name:"장흥군",type:"38",type2:"20"},
		{name:"진도군",type:"38",type2:"21"},
		{name:"함평군",type:"38",type2:"22"},
		{name:"해남군",type:"38",type2:"23"},
		{name:"화순군",type:"38",type2:"24"},
		{name:"서귀포시",type:"39",type2:"3"},
		{name:"제주시",type:"39",type2:"4"},
		{name:"",type:"",type2:""},
		]);
		const openErrorAlert = ref(false)
		const locaition1 = ref("1")
		const locaition2 = ref("1")
		const checkedList = ref([]);
		const openTerm = ref(null);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
		const userData = computed(() => store.state.userData);


        onMounted(() => {
            store.dispatch("common/fetchCd086")
            // store.dispatch("common/fetchta111")
        })
		
		const menus = computed(() => loc_cd1.value.reduce((acc, cur) => {
				const isIn = acc.find(item => item.id === cur.type);
				if (!isIn && cur.type !== "00") {
					acc.push({id: cur.type, name: cur.name});
				}
				return acc;
			}, []));
		const locations = computed(() => loc_cd2.value
			.filter((location) => location.type === selectedMenuId.value )
			.reduce((acc, cur) => {
				if(cur.area_cd1 !== "00") {
					acc.push({id: cur.type2, name: cur.name});
				}
				return acc;
		}, []));
		
		
		const handleMenu = (menuId) => {
			selectedMenuId.value = menuId;
		};

		const value = ['privacy3', 'privacy'];
		const allChecked = computed(
			() => (value
				.filter(checked => checkedList.value.includes(checked)))
			.length === 2
		);

		const allCheck = (event) => {
			if (event.target.checked) {
				checkedList.value = value;
			} else {
				checkedList.value = [];
			}
		};
		

		const handleSelectLocation = (locationId) => {
			//cd086안의 "전체 전체"라는 이상한 값 필터
			const location = store.state.common.cd086.filter(item => item.area_cd1 !== "00").find(item => item.area_cd2 === locationId);
			if (location) {
				store.commit("mhm01/SET_SELECT_LOCATION", {location});
			}
			if(route.query.callbackUrl) {
				router.replace(route.query.callbackUrl);
			} else {
				router.back();
			}
		};

		const handleClickSb = () => {
			if(!isLogin.value){
				router.push("/layers/login")
				return;
			}
			if(!allChecked.value) {
				//alert("이용약관에 모두 동의해주세요.");
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "이용약관에 모두 동의해주세요."});
				openErrorAlert.value = true;
				return;
			}
			 if(userData.value.mem_ci === undefined){
			 	router.push('/layers/Sbdeajeon/auth')
			 	// router.push("/layers/SB_AUTH")
			 	return;
			 }
			mpj.getpj101({mem_id: userData.value.mem_id
			,mem_ci:userData.value.mem_ci
			,mem_birth:userData.value.mem_brith
			,mem_gender:userData.value.mem_gender
			,cpType:"20T" 									//기준이 명확치 않아서 우선 20T로 설정
			,mem_terms1:"Y"
			,mem_terms2:"Y"
			,mem_area1:locaition1.value
			,mem_area2:locaition2.value
			})
			.then((data) => {
				if(userData.value.SBCP === "1"){
					router.push('/layers/Sbdeajeon/result/2')
					return;
					//alert('인증 실패했습니다.')
				}
				if(data.data.Res_cd === "0000" && userData.value.SBCP === undefined) {
					store.commit("SET_USERDATA", {userData: {
						...userData.value,
						SBCP: "1"
					}});
					router.push('/layers/Sbdeajeon/result/1')
					return;
					//alert('인증 성공했습니다.')
				}
				
			})
		}
		return {
			menus,
			selectedMenuId,
			locations,
			loc_cd1,
			loc_cd2,
			locaition1,
			locaition2,
			openTerm,
			checkedList,
			allCheck,
			allChecked,
			openErrorAlert,
			t,  //번역필수 모듈
		  i18n, 
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			handleOpenTerm: (type) => {
				openTerm.value = type;
			},
			handleCloseTerm: () => {
				openTerm.value = null;
			},
			handleMenu,
			handleSelectLocation,
			userData,
			isLogin,
			handleClickSb,
			goBack (){
				router.push('/')
			}
		}
	}
}
</script>