import fetch from "./fetch";

const PJ_100 = () => "/PJ/WPJ100_S100";
const PJ_101 = () => "/PJ/WPJ100_S101";
const PJ_102 = () => "/PJ/WPJ100_S102";
const PJ_103 = () => "/PJ/WPJ100_S103";
const PJ_104 = () => "/PJ/WPJ100_S104";
const PJ_105 = () => "/PJ/WPJ100_S105";
const PJ_106 = () => "/PJ/WPJ100_S106";

export default {
  getpj100: fetch("GET", PJ_100),
  getpj101: fetch("GET", PJ_101),
  getpj102: fetch("GET", PJ_102),
  getpj103: fetch("GET", PJ_103),
  getpj104: fetch("GET", PJ_104),
  getpj105: fetch("GET", PJ_105),
  getpj106: fetch("GET", PJ_106),
}